<template>
  <v-btn
    :style="style"
    v-text="control.config.text"
    :height="control.size.height"
    :color="control.style['background-color']"
    :dark="control.config.theme == 'dark'"
    block
    @click="onClick"
  ></v-btn>
</template>

<script>
import client from "../utils/client";
export default {
  props: { control: Object, isView: Boolean },
  computed: {
    style() {
      let result = JSON.parse(JSON.stringify(this.control.style));
      delete result["background-color"];
      return result;
    },
  },
  methods: {
    onClick() {
      if (this.isView) {
        for (let i in this.control.config.actions) {
          let action = this.control.config.actions[i];
          if (action.action.type) {
            client.$emit(action.action.type, action.action);
          }
        }
      }
    },
  },
};
</script>
